import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import { mobileThresholdPixels, fontSizes, colors, Title1, Container, Content, HeaderPhotoContainer }
  from '../../components/Home/v2/StyledComponents';
import MenuBar from '../../components/Home/MenuBar';
import TheWhy from '../../components/BeTilliste/TheWhy';
import TheHow from '../../components/BeTilliste/TheHow';
import TheWhat from '../../components/BeTilliste/TheWhat';
import TillisteFeedbacks from '../../components/BeTilliste/TillisteFeedbacks';
import Footer from '../../components/Home/Footer';
import headerPhotoDesktop from '../../assets/beTilliste/beTillisteHeader.png';
import headerPhotoMobile from '../../assets/beTilliste/beTillisteHeader-mobile.png';
import Layout from '../../layouts/index';

const GreyBackground = styled.div`
  background-color: ${colors.lightGrey};
`;

const StyledTitle1 = styled(Title1)`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    margin: 25vw 3vw;
  }
`;

const Emph = styled.span`
  font-size: 64px
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 35px
  }
`;

class BeTillistePage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { isMobile } = this.props.context;
    const headerPhoto = isMobile ? headerPhotoMobile : headerPhotoDesktop;
    return (
      <Layout routeSlug="BeTillistePage">
        <Container>
          <MenuBar />
          <HeaderPhotoContainer backgroundImage={headerPhoto}>
            <StyledTitle1>Vivez de<br /><Emph>votre</Emph><br />passion</StyledTitle1>
          </HeaderPhotoContainer>
          <Content><TheWhat /></Content>
          <Content><TheWhy /></Content>
          <GreyBackground>
            <Content><TheHow /></Content>
          </GreyBackground>
          <Content><TillisteFeedbacks /></Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

BeTillistePage.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(BeTillistePage);
